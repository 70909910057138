import { useMemo } from "react";
import { selectWeek } from "./helper";
import { UserHuddles } from "../_types";

export function useUserHuddleStreakCount(userHuddles: UserHuddles) {
  const currentWeek = selectWeek(new Date());

  const formatedTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const formatedUserHuddles = useMemo(() => {
    return userHuddles
      ? Object.entries(userHuddles).map(([key]) => {
          return { date: key };
        })
      : [];
  }, [userHuddles]);

  const weeklyUserHuddleCount = useMemo(() => {
    const weeklyHuddles = currentWeek.map((weekday) => ({
      ...weekday,
      hasHuddled:
        formatedUserHuddles.some(
          (userHuddle) => weekday.date === userHuddle.date.split("T")[0],
        ) && weekday.date <= formatedTodayDate(),
    }));

    return weeklyHuddles.filter((weekday) => weekday.hasHuddled).length;
  }, [currentWeek, formatedUserHuddles]);

  return weeklyUserHuddleCount;
}
