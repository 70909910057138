"use client";
import React from "react";
import Link from "next/link";
import { StarOutlined, DashboardOutlined } from "@ant-design/icons";
import GamesIcon from "../../ui/graphics/GamesIcon";
import VaultIcon from "../../ui/graphics/VaultIcon";

const HomeFooter = () => {
  return (
    <div
      id="homeFooter"
      className="bg-dark text-white p-5 md:p-10 w-full pb-40 sm:pb-10 -mb-32 user-select-none"
    >
      <div className="w-full max-w-[340px] mx-auto sm:max-w-none relative left-[2.5%] sm:left-0">
        <div className="grid grid-cols-2 lg:hidden gap-4 w-full max-w-6xl mx-auto">
          <Link
            className="flex items-center gap-4 text-lg sm:px-8"
            href="/my-list"
          >
            <StarOutlined className="scale-125" />
            MY LIST
          </Link>
          <Link
            className="flex items-center gap-4 text-lg sm:px-8"
            href="/dashboard"
          >
            <DashboardOutlined className="scale-125" />
            DASHBOARD
          </Link>
          <Link
            className="flex items-center gap-4 text-lg sm:px-8"
            href="/games"
          >
            <GamesIcon className="scale-125" />
            GAMES
          </Link>
          <Link
            className="flex items-center gap-4 text-lg sm:px-8"
            href="/reading-list/vault"
          >
            <VaultIcon className="scale-125" />
            VAULT
          </Link>
        </div>
        <div className="hidden lg:flex justify-between gap-4 w-full max-w-6xl mx-auto">
          <Link
            className="flex items-center gap-4 text-lg sm:px-8"
            href="/my-list"
          >
            <StarOutlined className="scale-125" />
            MY LIST
          </Link>
          <Link
            className="flex items-center gap-4 text-lg sm:px-8"
            href="/games"
          >
            <GamesIcon className="scale-125" />
            GAMES
          </Link>
          <Link
            className="flex items-center gap-4 text-lg sm:px-8"
            href="/dashboard"
          >
            <DashboardOutlined className="scale-125" />
            DASHBOARD
          </Link>
          <Link
            className="flex items-center gap-4 text-lg sm:px-8"
            href="/reading-list/vault"
          >
            <VaultIcon className="scale-125" />
            VAULT
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeFooter;
