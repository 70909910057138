import React, { useState } from "react";
import Image from "next/image";
import { cn } from "@/app/_utils/ui";

interface ImageWithFallbackProps {
  src: string;
  fallback: string;
  alt?: string;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  src,
  fallback,
  alt,
}) => {
  const [hasError, setHasError] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  return (
    <Image
      className={cn(
        "w-full transition-all duration-150",
        hasLoaded ? "opacity-100" : "opacity-0",
      )}
      src={hasError ? fallback : src}
      alt={alt || ""}
      fill={true}
      unoptimized={true}
      objectFit="cover"
      onError={() => {
        if (!hasError) setHasError(true);
      }}
      onLoad={() => {
        setHasLoaded(true);
      }}
    />
  );
};

export default React.memo(ImageWithFallback);
