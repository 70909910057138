import React from "react";
import { cn } from "@/app/_utils/ui";

// Extend the component props with React.HTMLAttributes<HTMLHeadingElement>
interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode;
  className?: string;
}

export const Heading = ({ children, className, ...props }: HeadingProps) => {
  return (
    <h3
      {...props}
      className={cn("text-2xl font-display font-bold uppercase", className)}
    >
      {children}
    </h3>
  );
};
