import * as React from "react";
import { cn } from "@/app/_utils/ui";

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "padded";
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className, variant, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          "rounded-2xl border border-neutral-300 bg-white transition-all ease-in-out duration-500",
          variant === "padded" && "p-6 pb-8",
          className,
        )}
        {...props}
      />
    );
  },
);

Card.displayName = "Card";

export { Card };
