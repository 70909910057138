"use client";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/app/_components/reducers/store";
import HomeHeaderGreeting from "./HomeHeaderGreeting";
import HomeHeaderBadge from "./HomeHeaderBadge";

const HomeHeader = () => {
  const { firstName } = useSelector(
    (state: RootState) => state.userReadArticles,
  );

  const { isLoading, userStreakStats } = useSelector(
    (state: RootState) => state.userStreakStats,
  );

  return (
    <div id="homeHeader" className="w-full bg-white">
      <div className="w-full pt-5 pb-8 md:pb-6">
        <div className="grid sm:grid-cols-2 items-center">
          <div className="flex items-start md:items-center gap-5 px-6 md:pl-10 md:py-10">
            <HomeHeaderGreeting name={firstName} />
          </div>
          <div className="w-full flex justify-end md:pr-16">
            <div className="flex justify-end sm:gap-2 px-3 w-full max-w-[540px]">
              {!isLoading && (
                <>
                  <HomeHeaderBadge
                    imgSrc="/assets/images/badges/badge-huddler.svg"
                    imgAlt="perfect huddler badge"
                    badgeCount={
                      userStreakStats?.badges.perfect_huddler.year_to_date
                    }
                    badgeLabel="perfect huddler"
                  />
                  <HomeHeaderBadge
                    imgSrc="/assets/images/badges/badge-starter.svg"
                    imgAlt="huddle starter badge"
                    badgeCount={userStreakStats?.huddles_started}
                    badgeLabel="huddle starter"
                  />
                  <HomeHeaderBadge
                    imgSrc="/assets/images/badges/badge-reader.svg"
                    imgAlt="daily article whiz badge"
                    badgeCount={userStreakStats?.badges.bookworm.year_to_date}
                    badgeLabel="daily article whiz"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
