const Exit = ({ className }: { className?: string }) => (
  <div className={className}>
    <svg
      width="101"
      height="97"
      viewBox="0 0 101 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M85.0357 63.2637H14.6685C6.75648 63.2637 0.335144 56.8423 0.335144 48.9303C0.335144 41.0183 6.75648 34.597 14.6685 34.597H85.0452C92.9573 34.597 99.3786 41.0183 99.3786 48.9303C99.3786 56.8423 92.9573 63.2637 85.0357 63.2637Z"
        fill="#FAAD1B"
      />
      <path
        d="M52.7474 96.9375C49.0781 96.9375 45.4088 95.5424 42.609 92.7426C37.0094 87.143 37.0094 78.0653 42.609 72.4753L66.1443 48.9399L42.6185 25.4045C37.019 19.805 37.019 10.7272 42.6185 5.13717C48.2181 -0.462391 57.2959 -0.462391 62.8859 5.13717L96.5501 38.8014C102.15 44.401 102.15 53.4788 96.5501 59.0688L62.8859 92.733C60.0861 95.5328 56.4168 96.9375 52.7474 96.9375Z"
        fill="#FAAD1B"
      />
      <path
        d="M85.0453 47.7359H14.6685V50.1248H85.0453V47.7359Z"
        fill="black"
      />
      <path
        d="M53.5979 83.445L51.9066 81.7537L84.7299 48.9303L51.9066 16.107L53.5979 14.4252L88.103 48.9303L53.5979 83.445Z"
        fill="black"
      />
    </svg>
  </div>
);

export default Exit;
