"use client";
import React from "react";
import Link from "next/link";
import { Card } from "../../ui/card";
import { Button } from "../../ui/buttons/button";
import { Heading } from "../../ui/typography/Heading";

const HomeCard = ({
  title,
  subtitle,
  href,
  graphic,
  buttonText,
}: {
  title: React.ReactNode;
  subtitle: string;
  href: string;
  graphic: React.ReactNode;
  buttonText: string;
}) => {
  return (
    <Card className="hover:border-neutral-600 hover:scale-[1.015]">
      <Link
        className="group flex flex-col items-center gap-2 text-center pt-6 pb-8 px-3"
        href={href}
      >
        {graphic}
        <div className="pb-6">
          <Heading>{title}</Heading>
          <p className="text-neutral-700 line-clamp-1 h-6">{subtitle}</p>
        </div>
        <Button className="bg-dark group-hover:bg-neutral-800 group-hover:ring-neutral-800 ring-0 group-hover:ring-1 border-0 group-hover:border-4 group-hover:border-white group-hover:h-12 relative group-hover:-top-.5 group-hover:-mb-2 transition-all ease-in-out duration-500 hover:outline-offset-none hover:outline-transparent">
          {buttonText}
        </Button>
      </Link>
    </Card>
  );
};

export default HomeCard;
