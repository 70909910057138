"use client";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@/app/_components/reducers/store";
import { RootState } from "@/app/_components/reducers/store";
import { fetchWeeklyStats } from "@/app/_components/reducers/weeklyStatsSlice";
import { fetchUserStreakStats } from "../../reducers/userStreakStatsSlice";
import { fetchDailyArticle } from "../../reducers/dailyArticleSlice";
import { fetchReadingLists } from "../../reducers/readingListsSlice";
import HomeHeader from "./HomeHeader";
import HomeHuddle from "./HomeHuddle";
import HomeStats from "./HomeStats";
import HomeCards from "./HomeCards";
import HomeFooter from "./HomeFooter";
import { handleLoginError } from "@/app/_utils/auth";

const HomeView = () => {
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useSelector(
    (state: RootState) => state.login.accessToken,
  );

  useEffect(() => {
    if (accessToken) {
      Promise.all([
        dispatch(fetchWeeklyStats(accessToken)),
        dispatch(fetchReadingLists(accessToken)),
        dispatch(fetchDailyArticle(accessToken)),
        dispatch(fetchUserStreakStats(accessToken)),
      ]).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err.code, JSON.stringify({ err }));
        if (
          err.code === "ERR_BAD_REQUEST" ||
          err.message.includes("401") ||
          err.message.includes("incoming token has expired")
        ) {
          handleLoginError(dispatch);
        }
      });
    }
  }, [dispatch, accessToken]);

  return (
    <>
      <div className="bg-darkblue-300/10">
        <HomeHeader />
        <HomeHuddle />
        <HomeCards />
        <HomeStats />
      </div>
      <HomeFooter />
    </>
  );
};

export default HomeView;
