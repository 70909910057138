"use client";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@/app/_components/reducers/store";
import { RootState } from "@/app/_components/reducers/store";
import { undoJoinHuddle } from "../../reducers/huddleSlice";
import { ReloadOutlined } from "@ant-design/icons";
import { isDevelopment } from "@/app/_utils/helper";

const ResetVegxHuddle = ({ huddle }: { huddle: any }) => {
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useSelector(
    (state: RootState) => state.login.accessToken,
  );

  if (!isDevelopment() || !huddle) {
    return null;
  }

  console.log({ huddle });

  const onResetVegHuddle = async () => {
    if (!accessToken) {
      throw new Error("No access token");
    }

    await dispatch(
      undoJoinHuddle({
        articleId: huddle.articleId,
        body: {
          huddleId: huddle.huddle_id,
          huddleTime: huddle.huddle_time.replace("+00:00", ".000"),
        },
        accessToken,
      }),
    ).unwrap();
  };

  return (
    <button
      className="absolute bottom-0 left-2 opacity-50 hover:opacity-100"
      onClick={onResetVegHuddle}
      title="Reset Vegx Huddle"
    >
      <ReloadOutlined />
    </button>
  );
};

export default ResetVegxHuddle;
