const Spike7 = ({ className }: { className?: string }) => (
  <div className={className}>
    <svg
      width="148"
      height="96"
      viewBox="0 0 148 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_628_1500)">
        <path
          d="M75.8083 53.1827L110.434 57.7526L138.097 41.1901L118.357 11.2288L95.805 24.8168L68.2504 27.6967L75.8083 53.1827Z"
          fill="#76CFE7"
        />
        <path
          d="M97.495 39.0538L75.5378 29.2245C71.7115 27.5074 69.9809 22.978 71.698 19.1518C73.4151 15.3255 77.9445 13.5949 81.7707 15.312L103.728 25.1413C107.554 26.8584 109.285 31.3877 107.568 35.214C105.851 39.0403 101.321 40.7709 97.495 39.0538Z"
          fill="#76CFE7"
        />
        <path
          d="M32.8674 12.8242L48.8756 22.4643L30.3661 53.1691L13.5468 43.0288L32.8674 12.8242Z"
          fill="#76CFE7"
        />
        <path
          d="M110.65 68.5824L86.8138 54.2102C83.9745 52.4931 83.0551 48.775 84.7587 45.9357C86.4758 43.0964 90.1939 42.177 93.0332 43.8806L116.87 58.2393C119.709 59.9564 120.628 63.6745 118.925 66.5138C117.208 69.3666 113.49 70.286 110.65 68.5824Z"
          fill="#76CFE7"
        />
        <path
          d="M102.105 77.587L78.6205 63.4311C75.6866 61.6599 74.7266 57.8066 76.4978 54.8727C78.269 51.9388 82.1223 50.9788 85.0562 52.75L108.541 66.9059C111.475 68.677 112.435 72.5303 110.664 75.4643C108.893 78.3982 105.039 79.3582 102.105 77.587Z"
          fill="#76CFE7"
        />
        <path
          d="M92.3978 84.7934L68.5613 70.4347C65.722 68.7176 64.8026 64.9995 66.5062 62.1602C68.2233 59.3209 71.9414 58.4015 74.7807 60.1051L98.6172 74.4638C101.456 76.1809 102.376 79.899 100.672 82.7383C98.9552 85.5775 95.2371 86.5104 92.3978 84.7934Z"
          fill="#76CFE7"
        />
        <path
          d="M83.6095 91.7969L59.7865 77.4247C56.9472 75.7076 56.0279 71.9895 57.7314 69.1502C59.4485 66.3109 63.1666 65.3916 66.0059 67.0951L89.8424 81.4538C92.6817 83.1709 93.6011 86.889 91.8975 89.7283C90.1804 92.5811 86.4488 93.5005 83.6095 91.7969Z"
          fill="#76CFE7"
        />
        <path
          d="M35.0577 63.377L47.5641 70.9079C53.8241 74.6801 62.0174 72.6385 65.7896 66.3921L71.4682 46.2061C75.2404 39.9461 79.9996 32.0908 73.7396 28.3186L61.2332 20.7877C54.9733 17.0155 46.7799 19.0571 43.0077 25.3035L30.5284 45.165C26.7697 51.4115 28.7978 59.6048 35.0577 63.377Z"
          fill="#76CFE7"
        />
        <path
          d="M72.6986 30.6577L96.0348 44.7054L81.0271 70.4212L59.5973 58.0365L72.6986 30.6577Z"
          fill="#76CFE7"
        />
        <path
          d="M24.5117 2.00793L42.4533 13.5273L19.0089 50.073L1.06732 38.5536"
          fill="white"
        />
        <path
          d="M24.5117 2.00793L42.4533 13.5273L19.0089 50.073L1.06732 38.5536"
          stroke="black"
          strokeWidth="3.3801"
          strokeMiterlimit="10"
        />
        <path
          d="M82.0682 28.9406L62.734 43.2452C59.3674 45.7329 54.5677 45.0163 52.0799 41.6497C49.5922 38.2832 50.3087 33.4834 53.6753 30.9957L73.0095 16.6911C76.3761 14.2033 81.1758 14.9199 83.6636 18.2865C86.1649 21.6531 85.4483 26.4528 82.0682 28.9406Z"
          fill="#373B94"
        />
        <path
          d="M59.6784 63.2689L50.1194 80.1559C48.4564 83.1033 44.6842 84.1444 41.7368 82.4814C38.7893 80.8184 37.7482 77.0462 39.4113 74.0987L48.9702 57.2117C50.6332 54.2643 54.4054 53.2232 57.3528 54.8862C60.3003 56.5628 61.3414 60.335 59.6784 63.2689Z"
          fill="#76CFE7"
        />
        <path
          d="M70.062 70.989L61.125 85.6857C59.462 88.6332 55.6898 89.6743 52.7424 88.0112C49.7949 86.3482 48.7539 82.576 50.4169 79.6286L59.3539 64.9319C61.0169 61.9845 64.7891 60.9434 67.7365 62.6064C70.684 64.2694 71.7386 68.0416 70.062 70.989Z"
          fill="#76CFE7"
        />
        <path
          d="M48.2807 58.3745L40.9932 71.273C39.3302 74.2204 35.558 75.2615 32.6105 73.5985C29.6631 71.9355 28.622 68.1633 30.285 65.2158L37.5725 52.3173C39.2355 49.3699 43.0077 48.3288 45.9552 49.9918C48.9026 51.6548 49.9572 55.427 48.2807 58.3745Z"
          fill="#76CFE7"
        />
        <path
          d="M80.27 78.1819L72.9825 91.0803C71.3195 94.0278 67.5473 95.0688 64.5998 93.4058C61.6524 91.7428 60.6113 87.9706 62.2743 85.0232L69.5618 72.1247C71.2248 69.1773 74.997 68.1362 77.9445 69.7992C80.8919 71.4622 81.933 75.2344 80.27 78.1819Z"
          fill="#76CFE7"
        />
        <path
          d="M123.278 2.00787L105.337 13.5273L128.781 50.0729L146.723 38.5536"
          fill="white"
        />
        <path
          d="M123.278 2.00787L105.337 13.5273L128.781 50.0729L146.723 38.5536"
          stroke="black"
          strokeWidth="3.3801"
          strokeMiterlimit="10"
        />
        <path
          d="M99.3608 23.1943L81.2975 15.1091C78.8503 14.014 76.0786 14.4331 74.0776 15.9609C73.8613 16.0826 73.6449 16.2313 73.4421 16.38L53.2562 31.3066C50.1194 33.6321 49.4434 38.0938 51.7689 41.2306L52.3909 42.0688C54.7164 45.2056 59.1781 45.8816 62.3148 43.5561L79.3776 30.9415"
          fill="#76CFE7"
        />
        <path
          d="M99.3608 23.1943L81.2975 15.1091C78.8503 14.014 76.0786 14.4331 74.0776 15.9609C73.8613 16.0826 73.6449 16.2313 73.4421 16.38L53.2562 31.3066C50.1194 33.6321 49.4434 38.0938 51.7689 41.2306L52.3909 42.0688C54.7164 45.2056 59.1781 45.8816 62.3148 43.5561L79.3776 30.9415"
          stroke="black"
          strokeWidth="3.3801"
          strokeMiterlimit="10"
        />
        <path
          d="M59.6784 63.2689L50.1195 80.1559C48.4565 83.1033 44.6843 84.1444 41.7368 82.4814C38.7894 80.8184 37.7483 77.0462 39.4113 74.0987L48.9703 57.2117C50.6333 54.2643 54.4055 53.2232 57.3529 54.8862C60.3004 56.5628 61.3414 60.335 59.6784 63.2689Z"
          stroke="black"
          strokeWidth="3.3801"
          strokeMiterlimit="10"
        />
        <path
          d="M70.0621 70.989L61.1251 85.6857C59.4621 88.6332 55.6899 89.6743 52.7424 88.0112C49.795 86.3482 48.7539 82.576 50.4169 79.6286L59.3539 64.9319C61.0169 61.9845 64.7891 60.9434 67.7366 62.6064C70.684 64.2694 71.7386 68.0416 70.0621 70.989Z"
          stroke="black"
          strokeWidth="3.3801"
          strokeMiterlimit="10"
        />
        <path
          d="M48.2807 58.3745L40.9932 71.273C39.3302 74.2204 35.558 75.2615 32.6105 73.5985C29.6631 71.9355 28.622 68.1633 30.285 65.2158L37.5725 52.3173C39.2355 49.3699 43.0077 48.3288 45.9552 49.9918C48.9026 51.6548 49.9572 55.427 48.2807 58.3745Z"
          stroke="black"
          strokeWidth="3.3801"
          strokeMiterlimit="10"
        />
        <path
          d="M80.27 78.1819L72.9825 91.0804C71.3195 94.0278 67.5473 95.0689 64.5999 93.4059C61.6524 91.7429 60.6113 87.9707 62.2744 85.0232L69.5619 72.1248C71.2249 69.1773 74.9971 68.1362 77.9445 69.7993C80.892 71.4623 81.933 75.2345 80.27 78.1819Z"
          stroke="black"
          strokeWidth="3.3801"
          strokeMiterlimit="10"
        />
        <path
          d="M113.287 56.0355L83.3526 37.9316"
          stroke="black"
          strokeWidth="3.3801"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_628_1500">
          <rect width="148" height="96" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default Spike7;
